<template>
  <div class="border-t border-forgd-gray-300 pl-10 py-12 mt-20">
    <div class="grid grid-cols-12 max-w-7xl">
      <div class="col-span-7 ">
        <p class="font-mono text-forgd-primary-300 text-base font-normal mb-4">
          Need support?
        </p>

        <p
          class="text-forgd-primary-900 leading-tight text-4xl font-normal mb-8"
        >
          <slot>
            Contact us for troubleshooting or tips on how to utilize our tools.
          </slot>
        </p>

        <slot name="cta">
          <UiButton class="px-12 py-3" @click="navigateTo('/support/')">
            Contact Support
          </UiButton>
        </slot>
      </div>

      <div class="col-span-2" />

      <div class="col-span-3">
        <client-only>
          <Vue3Lottie
            animation-link="https://lottie.host/3e36f507-c59e-4e44-a3fc-10e040ddcb41/kWftBITXT1.json"
            class="h-52 w-52"
          />
        </client-only>
      </div>
    </div>
  </div>
</template>
